import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image'
import Content from './style'
import { SuperTag } from '~components'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function PengajuanSurvei() {
  const { t } = useTranslation()

  return (
    <Content>
      {/* <Content.SectionShape>
        <Img
          src='../../../assets/image/project-management/l2-content-1-shape.png'
          alt='content'
          layout='fullWidth'
          placeholder='blurred'
        />
      </Content.SectionShape> */}
      <Container>
        <Row className='justify-content-center'>
          <Col className='col-xl-8 text-center'>
            <Content.Box mb='5px'>
              {/* <Blog.Subtitle as='h6' fontColor='#5034fc'>
                Blog
              </Blog.Subtitle>
              whyChooseUs */}
              <Content.NewTittle as='h2'>
                {t('work', { ns: 'service' })}
                {/* {t('whyChooseUs', { ns: 'service' })} */}
              </Content.NewTittle>
            </Content.Box>
          </Col>
        </Row>
        <Content.Inner>
          <Row className='align-items-center justify-content-center justify-content-lg-start'>
            <Col className='col-xl-5 col-lg-6 col-md-8 col-xs-10 order-2 order-lg-1'>
              <Content.TextBlock>
                <Content.Icon as='div'>
                  <span>1</span>
                </Content.Icon>
                <Content.Title as='h2'>
                  {' '}
                  <SuperTag value={t('Fill', { ns: 'service' })} />
                </Content.Title>
                <Content.Text>
                  {' '}
                  <SuperTag value={t('Complete', { ns: 'service' })} />
                  <br className='d-none d-xs-block' />{' '}
                </Content.Text>
                {/* <Content.Button to='/information'>
                  {t('learnMore', { ns: 'home' })}
                </Content.Button> */}
              </Content.TextBlock>
            </Col>
            <Col
              xs='12'
              className='col-xl-7 col-lg-6 col-md-8 col-xs-10 order-1 order-lg-2'
            >
              <Content.Image>
                <Img
                  className='w-100'
                  src='../../../assets/image/project-management/l2-content-img-Pengajuan.webp'
                  alt='content'
                  // layout='fullWidth'
                  placeholder='blurred'
                />
                {/* <Content.ContentShape>
                  <Img
                    className='w-100'
                    src='../../../assets/image/project-management/l2-content-shape-2.png'
                    alt='content'
                    layout='fullWidth'
                    placeholder='blurred'
                  />
                </Content.ContentShape> */}
              </Content.Image>
            </Col>
          </Row>
        </Content.Inner>
      </Container>
    </Content>
  )
}
