import React from 'react'
import ServicesCard from './Components/Card'
import Service from './style'
import SectionTitle from './Components/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
export default function ServicesSection() {
  const { t } = useTranslation()
  return (
    <Service>
      <Container className='container'>
        <Row className='row justify-content-center'>
          {/* Single Services */}
          <Col xs='12' className='col-xl-3 col-lg-4 col-md-6 col-xs-8'>
            <ServicesCard
              title={t('freeInstallation', { ns: 'service' })}
              icon='fas fa-tools'
              iconColor='#EA4624'
              text={t('freeInstallationStatement', { ns: 'service' })}
            />
          </Col>
          {/*/ .Single Services */}
          {/* Single Services */}
          <Col className='col-xl-3 col-lg-4 col-md-6 col-xs-8'>
            <ServicesCard
              title={t('offGridSystem', { ns: 'service' })}
              icon='fas fa-bolt'
              iconColor='#38AF76'
              text={t('offGridStatement', { ns: 'service' })}
            />
          </Col>
          {/*/ .Single Services */}
          <Col xs='12' className='col-xl-3 col-lg-4 col-md-6 col-xs-8'>
            <ServicesCard
              title={t('oneStopService', { ns: 'service' })}
              icon='fas fa-hands-helping'
              iconColor='#F47621'
              text={t('oneStopServiceStatement', { ns: 'service' })}
            />
          </Col>
          {/*/ .Single Services */}
          {/*/ .Single Services */}
          <Col xs='12' className='col-xl-3 col-lg-4 col-md-6 col-xs-8'>
            <ServicesCard
              title={t('handleAnywhere', { ns: 'service' })}
              icon='fas fa-gamepad'
              iconColor='#46AFDC'
              text={t('handleAnywhereStatement', { ns: 'service' })}
            />
          </Col>
          {/*/ .Single Services */}
        </Row>
      </Container>
    </Service>
  )
}
