import React from 'react'
import BreadCrumb from './style'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Row, Container } from 'react-bootstrap'
import { SuperTag } from '~components'
import { Images } from '~data'
import { useTranslation } from 'react-i18next'

export default function ServiceTitle({ colClasses, title, text, ...rest }) {
  const { t } = useTranslation()
  return (
    <BreadCrumb backgroundColor='#f3f4f6' {...rest}>
      <Container>
        <Row className='row justify-content-center text-center'>
          <Col className={colClasses ? colClasses : 'col-xl-5'}>
            <BreadCrumb.Title as='h2'>{`${t('services', {
              ns: 'services',
            })}`}</BreadCrumb.Title>
          </Col>
        </Row>
      </Container>
    </BreadCrumb>
  )
}
