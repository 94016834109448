import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image'
import Content from './style'
import { Link, SuperTag } from '~components'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function SurveiLokasi() {
  const { t } = useTranslation()

  return (
    <Content backgrounColor='#fff'>
      {/* <Content.ContentShape>
        <Img
          src='../../../assets/image/project-management/l2-content-2-shape.png'
          alt='content'
          layout='constrained'
          placeholder='blurred'
        />
      </Content.ContentShape> */}
      <Container>
        <Content.Block>
          <Row className='align-items-center justify-content-center justify-content-lg-start'>
            <Col className='col-xl-7 col-lg-6 col-md-8 col-xs-10'>
              <Content.Image>
                <Img
                  src='../../../assets/image/project-management/l2-content-img-SurveiLokasi.webp'
                  alt='content'
                  // layout='fullWidth'
                  placeholder='blurred'
                />
              </Content.Image>
            </Col>
            <Col xs='12' className='col-xl-5 col-lg-6 col-md-8 col-xs-10'>
              <Content.Box>
                <Content.Icon as='div'>
                  <span>2</span>
                </Content.Icon>
                <Content.Title as='h2'>
                  {' '}
                  <SuperTag value={t('SurveyLocation', { ns: 'service' })} />
                  {/* <SuperTag value={`${t('IOT', { ns: 'home' })}`} />{' '} */}
                </Content.Title>

                <Content.Text>
                  {' '}
                  <SuperTag
                    value={`${t('SobatRpTeam', { ns: 'service' })}`}
                  />{' '}
                </Content.Text>
              </Content.Box>
            </Col>
          </Row>
        </Content.Block>
      </Container>
    </Content>
  )
}
