import React from 'react'
import { PageWrapper } from '~components/Core'
import TitleSection from '~sections/Services/titleSection'
// import Blog from '~sections/Services/blog'
import FooterSix from '~sections/Home/footerSix'
import PromoSection from '~sections/Services/promo'

import PengajuanSurvei from '~sections/Services/PengajuanSurvei'
import SurveiLokasi from '~sections/Services/surveiLokasi'
import PemasanganAlat from '~sections/Services/pemasanganAlat'
import Senang from '~sections/Services/senang'

// import EbtSection from '~sections/Home/ebtSection'
// import IotSection from '~sections/Home/iotSection'
import ServicesSection from '~sections/Services/serviceSection'
import { graphql } from 'gatsby'

const header = {
  headerClasses:
    'site-header site-header--menu-end dark-header site-header--sticky',
  containerFluid: false,
  darkLogo: false,
}

export default function Services() {
  return (
    <PageWrapper headerConfig={header}>
      <TitleSection />
      <ServicesSection />

      <PengajuanSurvei />
      <SurveiLokasi />
      <PemasanganAlat />
      <Senang />

      {/* <Blog /> */}
      <PromoSection />
      <FooterSix />
    </PageWrapper>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
