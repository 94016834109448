import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Images } from '~data'
import Promo from './style'
import { Link } from '~components'
import { useTranslation } from 'react-i18next'

export default function PromoSection({ ...rest }) {
  const { t } = useTranslation()
  return (
    <Promo backgroundColor='#38AF76'>
      <Container>
        <Row className='justify-content-center text-center'>
          <Col className='col-xl-8'>
            <Promo.Content>
              <Promo.Title fontColor='#fff' as='h2' mb='45px'>
                {t('promo', { ns: 'info' })}
              </Promo.Title>
              <Promo.Text fontColor='#fff' mb='30px'>
                {t('bePart', { ns: 'info' })}
              </Promo.Text>
              <Promo.Button background='#ff5722' as={Link} to='/survey-request'>
                {t('join', { ns: 'info' })}
              </Promo.Button>
            </Promo.Content>
          </Col>
        </Row>
      </Container>
      <Promo.Shape>
        <img
          className='w-100'
          src={Images.PageAboutUs.InnerPromoShape}
          alt='inner shape'
        />
      </Promo.Shape>
    </Promo>
  )
}
